import React from 'react'
import dataInfo from "../ArtistsData/data"
import logo from "../../images/Artists/logo.png"
import box1 from "../../images/Artists/box.png"
import box2 from "../../images/Artists/box2.png"
import { Link } from 'react-router-dom'

function AllArtists() {
  return (
      <div className='   flex justify-center items-center md:justify-between md:px-[2%] flex-col py-[1%] pb-[5%] '>
  
  <div className=' flex flex-wrap gap-[5.1%] items-center justify-start md:justify-center px-[2.5%] xl:px-[1.5%]  md:px-[2%] w-[80%] md:w-[100%] xl:w-[90%]'>
    
{dataInfo.map((artist)=>(
  <Link to={`/artist-biography/${artist.artistLink}`} key={artist.id} className=' pt-[2%]'>
  <img className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw]' src={artist.mainImage}/>
  <p className='py-[1%] text-[20px] lg:text-[12px] xl:text-[18px]'>{artist.artistName}</p>

</Link>


))
}


    </div>

    <div className=' py-[2%]  text-white flex flex-wrap gap-[5.1%] items-start justify-start  md:justify-center px-[2.5%] xl:px-[1.5%]  md:px-[2%] w-[80%] md:w-[100%] xl:w-[90%]'>
    
    <div className=' w-[22.1vw] xl:w-[25.5vw] md:w-[40vw]  flex justify-center items-center'>
      <img src={box1}/>
    </div>
    <div className='md:hidden'>
       <img className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw] ' src={logo}/>
    </div>

    <div className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw]  flex flex-col '>
      <img src={box2}/>
      <p className=' text-[#0A76BE] hover:underline hover:underline-offset-[5px] decoration-[2px] hover:text-[#FFAD09] cursor-pointer pt-[3%] text-[20px] lg:text-[12px] sm:text-[10px] xl:text-[18px]'>LET US EXPLORE YOUR WORK</p>
    </div>
    
    
    
    
    </div>
        
    </div>
  )
}

export default AllArtists