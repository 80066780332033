import React from 'react'
import logo from "../../images/Navbar/MarketPlaceLogo.png";
import { Link } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";
import { MdPhoneAndroid } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { AiFillFacebook } from "react-icons/ai";

function Footer() {
  
  return (
    <div className=' flex justify-center items-center p-[3%] '>
    <div className=' xl:w-[90%] w-[80%] flex gap-[5%] xl:gap-[3%] md:flex-col '>
        <div className='h-[100%] w-[22.1vw] xl:w-[25.5vw] md:w-[60vw]  '>
          <Link to="/">
            <img className='w-[200px] xl:w-[150px] lg:w-[100px]' src={logo} alt='market-place-logo' />
          </Link>
          <p className='text-[12px] lg:text-[10px] text-[gray]'>Beirut | Street Name 2024 | Osseiran Bldg</p>
         
        </div>
<div className='flex gap-[15%]'>


        <div className=' flex-col w-[22.1vw] xl:w-[25.5vw] md:w-[50vw] xl:text-[15px] lg:text-[12px] flex md:pt-[5%] px-[0.5%] xl:px-[2.5%] md:px-[0.5%]'>
           <p className='pb-[2%] text-[18px] lg:text-[15px] sm:text-[12px] text-[#0A76BE]'>About Us & More</p> 
            <Link to="/about" className={`hover:text-[#FFAD09] text-[12px] lg:text-[10px] w-[fit-content] hover:underline hover:underline-offset-[5px] hover:decoration-[2px]`}>About</Link>
            <Link to="/artists" className={`hover:text-[#FFAD09] text-[12px] lg:text-[10px] w-[fit-content] hover:underline hover:underline-offset-[5px] hover:decoration-[2px]`}>Artists</Link>
            <Link className={`hover:text-[#FFAD09] text-[12px] lg:text-[10px] w-[fit-content] hover:underline hover:underline-offset-[5px] hover:decoration-[2px]`}>Press</Link>
            <Link className={`hover:text-[#FFAD09] text-[12px] lg:text-[10px] w-[fit-content] hover:underline hover:underline-offset-[5px] hover:decoration-[2px]`}>Auctions</Link>
            <Link className={`hover:text-[#FFAD09] text-[12px] lg:text-[10px] w-[fit-content] hover:underline hover:underline-offset-[5px] hover:decoration-[2px]`}>Contact</Link>
        </div>
        <div className='  flex-col w-[22.1vw] xl:w-[25.5vw] md:w-[50vw]   xl:text-[15px] lg:text-[12px]  flex  md:pt-[5%]  px-[0.5%] xl:px-[2.5%] md:px-[0.5%]'>
          <p className=' text-[18px] lg:text-[15px] sm:text-[12px]  text-[#0A76BE]'>Contact Us</p> 
            <div className=' flex items-center gap-[2%] pt-[1%] '>
                <MdPhoneAndroid  className=' w-[25px] h-[25px] md:h-[18px] text-[#0A76BE]' />
                   <a href='/' className='hover:text-[#FFAD09] text-[12px] lg:text-[10px] w-[fit-content]  cursor-pointer  hover:underline hover:underline-offset-[5px] hover:decoration-[2px] '> test@gmail.com</a>
            </div>
            <div className=' flex items-center gap-[2%] pt-[1%] '>
                <MdOutlineEmail className=' w-[25px] h-[25px] md:h-[18px] text-[#0A76BE]' />
                   <a href='/' className='hover:text-[#FFAD09] text-[12px] lg:text-[10px] w-[fit-content]  cursor-pointer  hover:underline hover:underline-offset-[5px] hover:decoration-[2px] '> test@gmail.com</a>
            </div>
            
            <div className=' flex items-center gap-[2%] pt-[1%] '>
                <AiFillFacebook  className=' w-[25px] h-[25px] md:h-[18px] text-[#0A76BE]' />
                   <a href='/' className='hover:text-[#FFAD09] text-[12px] lg:text-[10px] w-[fit-content]  cursor-pointer  hover:underline hover:underline-offset-[5px] hover:decoration-[2px] '> facebook.lb</a>
            </div>
            <div className=' flex items-center gap-[2%] pt-[1%] '>
                <FaInstagram className=' w-[25px] h-[25px] md:h-[18px] text-[#0A76BE]' />
                   <a href='/' className='hover:text-[#FFAD09] text-[12px] lg:text-[10px] w-[fit-content]  cursor-pointer  hover:underline hover:underline-offset-[5px] hover:decoration-[2px] '> facebook.lb</a>
            </div>
           
            
         
            
        </div>
        </div>
    </div>
    </div>
  )
}

export default Footer