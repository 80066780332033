import React from 'react';
import { useParams } from 'react-router-dom';
import dataInfo from "../ArtistsData/data";
import logo from "../../images/Artists/logo.png";
import box1 from "../../images/Artists/box3.png";
import box2 from "../../images/Artists/box4.png";
import box from "../../images/Artists/blue-box.png"
import painting2 from "../../images/Artists/painting2.png"
import painting3 from "../../images/Artists/painting3.png"
function ArtistBio() {
  const { artistLink } = useParams();
  const artistData = dataInfo.find(artistBio => artistBio.artistLink === artistLink); 
  if (!artistData) {
    return <p>Artist not found</p>; 
  }

  return (
    <div className='flex justify-center items-center md:justify-between md:px-[2%] flex-col py-[1%] pb-[5%] md:py-[5%]'>
      <div className='flex flex-wrap gap-[5.1%] items-center justify-start md:justify-center px-[2.5%] xl:px-[1.5%] md:px-[2%] w-[80%] md:w-[100%] xl:w-[90%]'>
      
        <div key={artistData.id} className='flex items-end w-[94%] gap-[5%]'>
          <div className=' relative '>
            <img className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw]  ' src={box} alt="box background"/>
            <p className='text-[4vw] md:text-[8vw] w-[68%] text-center absolute top-[30%] left-[15%] font-[arial] tracking-tighter  font-[700] text-white leading-[0.9]'>{artistData.artistName}</p>
         <h1 className='pt-[2%] text-[20px] xl:text-[18px] lg:text-[15px]'>THE ARTIST</h1>
         </div>
          <div>
          <img className='w-[40vw]' src={artistData.mainImage} alt={artistData.artistName} />
          <p className='pt-[2%] text-[20px] xl:text-[18px] lg:text-[15px]'>painting Name </p>

          </div>
         
        </div>

        <div className='flex lg:flex-col-reverse w-[94%] gap-[5%] py-[5%]'>
          <div className='flex flex-col lg:flex-row gap-[30px] lg:gap-[5.5%]  md:px-[1%]'>
            <div>
             <img className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw]' src={painting3} alt="box decoration"/>
             <p className=' py-[2%]  text-[20px] xl:text-[18px] lg:text-[15px]'>painting Name</p>
            </div>
           <div>
           <img className='w-[22.1vw] xl:w-[25.5vw] mt-[30px] lg:mt-[0px] md:w-[40vw]' src={painting2} alt="box decoration"/>
            <p className=' py-[2%]  text-[20px] xl:text-[18px] lg:text-[15px]'>painting Name</p>

           </div>
            
           
          </div>
          <div className='w-[40vw] xl:w-[63%] lg:w-[100%] lg:mb-[5%]'>
            <p className='text-[20px] xl:text-[18px] lg:text-[15px]'>{artistData.bio}</p>
            <p className='text-[20px] xl:text-[18px] lg:text-[15px] mt-[2%]'>{artistData.bio1}</p>
          </div>
        </div>
      </div>

      <div className='py-[2%] text-white flex flex-wrap gap-[5.1%] items-start justify-start md:justify-center px-[2.5%] xl:px-[1.5%] md:px-[2%] w-[80%] md:w-[100%] xl:w-[90%]'>
        <div className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw] flex justify-center items-center'>
          <img src={box1} alt="box background" />
        </div>
        <div className='md:hidden'>
          <img className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw]' src={logo} alt="artist logo" />
        </div>
        <div className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw] flex flex-col'>
          <img src={box2} alt="box decoration" />
          <p className='text-[#0A76BE] hover:underline hover:underline-offset-[5px] decoration-[2px] hover:text-[#FFAD09] cursor-pointer pt-[3%] text-[20px] lg:text-[12px] sm:text-[10px] xl:text-[18px]'>
            LET US EXPLORE YOUR WORK
          </p>
        </div>
      </div>
    </div>
  );
}

export default ArtistBio;
