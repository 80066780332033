import React from 'react'
import MainNavbar from '../Components/Navbar/MainNavbar'
import AllArtists from '../Components/Artists/AllArtists'
import Footer from '../Components/Footer/Footer'

function ArtistsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <MainNavbar/> 
    <AllArtists/>
    <Footer/>
    </>
  )
}

export default ArtistsPage