import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import logo from "../../images/Navbar/MarketPlaceLogo.png";
import { FaBarsStaggered } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";

function MainNavbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to check if the link is active
  const isActiveLink = (path) => location.pathname === path ? 'underline underline-offset-[8px] decoration-[5px] text-[#FFAD09]' : '';

  return (
    <div className='flex items-center justify-center '>
      <div className='py-[3%] xl:px-[1%] flex justify-between items-center md:justify-between md:px-[2%] w-[75%] xl:w-[90%] '>
        <div className='h-[100%]'>
          <Link to="/">
            <img className='w-[180px] lg:w-[100px]' src={logo} alt='market-place-logo' />
          </Link>
          <p className='text-[12px] lg:text-[10px] text-[gray]'>Beirut | Street Name 2024 | Osseiran Bldg</p>
        </div>
        <div className='lg:hidden w-[49vw]  xl:w-[60vw]  text-[25px] 2xl:text-[20px] xl:text-[18px] lg:text-[15px]  flex justify-between xl:justify-between lg:pt-[5%] pt-[4%] px-[0.5%] xl:px-[2.5%]'>
          <Link to="/about" className={`hover:text-[#FFAD09]  ${isActiveLink('/about')} hover:underline hover:underline-offset-[8px] hover:decoration-[5px]`}>A b o u t</Link>
          <Link to="/artists" className={`hover:text-[#FFAD09]  ${isActiveLink('/artists')}  hover:underline hover:underline-offset-[8px] hover:decoration-[5px]`}>A r t i s t s</Link>
          <Link className={`hover:text-[#FFAD09]  ${isActiveLink('/press')} hover:underline hover:underline-offset-[8px] hover:decoration-[5px]`}>P r e s s</Link>
          <Link className={`hover:text-[#FFAD09]  ${isActiveLink('/auctions')} hover:underline hover:underline-offset-[8px] hover:decoration-[5px]`}>A u c t i o n s</Link>
          <Link className={`hover:text-[#FFAD09]  ${isActiveLink('/contact')} hover:underline hover:underline-offset-[8px] hover:decoration-[5px]`}>C o n t a c t</Link>
        </div>

        <div className='hidden lg:flex'>
          {menuOpen ? (
            <AiOutlineClose onClick={toggleMenu} className="w-[20px] h-[20px] z-[500]" />
          ) : (
            <FaBarsStaggered onClick={toggleMenu} className="w-[20px] h-[20px] z-[500]" />
          )}

          <div
            className={`fixed z-[10] inset-0 transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}
          >
            <div className="bg-[#0a76be] hidden lg:flex justify-center items-center flex-col h-full text-[20px] gap-[5%] text-white">
              <Link to="/about" className={` ${isActiveLink('/about')}`}>A b o u t</Link>
              <div className='flex justify-center items-center flex-col text-center w-[100%]'>
                <Link to="/artists" className={ `${isActiveLink('/artists')}`}>A r t i s t s</Link>
              </div>
              <Link className={`${isActiveLink('/press')}`  }>P r e s s</Link>
              <Link className={`${isActiveLink('/auctions')}` }>A u c t i o n s</Link>
              <Link className={`${isActiveLink('/contact')}`}>C o n t a c t</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainNavbar;
