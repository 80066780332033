import React from 'react'
import icon from "../../images/Home/marketplace-logo.png"
import logo from "../../images/Navbar/MarketPlaceLogo.png"

function Home() {
  return (
   <div className='flex justify-center items-center w-[100%]'>

   
        <div className=' bg-[#c3e0e6] lg:flex-col lg:h-[100%] w-[74.8%] xl:w-[90%]   py-[5%] h-[800px] 2xl:h-[700px] flex justify-between lg:justify-center lg:gap-[5%]  items-center '>
       <div className=' lg:h-[auto] h-[100%] w-[600px] xl:w-[550px] lg:w-[95%]    flex flex-col justify-end  '>
       <div className=' h-[80%] lg:h-[100%] flex flex-col justify-between'>
        <h1 className=' xl:text-[22px]  text-[25px] lg:text-[18px]  text-white '>We're thrilled to extend a warm invitation to all passionate admirers of art to connect with us! At the MARKET PLACE ART GALLERY, we understand the profound impact that art has on our lives- it inspires, challenges, and enriches our souls in ways words often cannot express.</h1>
       <img src={logo} alt='the-market-place-logo' className='w-[40%] lg:hidden'/>
       </div>
        </div> 
       <div className=' h-[100%] lg:w-[80%] md:w-[90%] lg:h-[500px] md:h-[auto] flex justify-center items-center md:mt-[5%]'>
         <img src={icon} className='object-contain h-[100%]' alt='the-market-place-icon' />
        </div> 
    </div>
    </div>

  )
}

export default Home