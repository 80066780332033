import React from 'react'
import MainNavbar from '../Components/Navbar/MainNavbar'
import AboutUs from '../Components/AboutUs/AboutUs'
import Footer from '../Components/Footer/Footer'

function AboutUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MainNavbar/>
      <AboutUs/>
      <Footer/>
    </>
  )
}

export default AboutUsPage