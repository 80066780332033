import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { FaBarsStaggered } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import logo from "../../images/Navbar/MarketPlaceLogo.png"

import dataInfo from "../ArtistsData/data"


function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  
  };

  
  return (
    <div className='flex justify-center items-center w-[100%] h-[150px]'>
   <div className='lg:hidden flex justify-between 2xl:justify-between text-[1.5vw] lg:text-[2vw] w-[75%] xl:w-[90%] lg:w-[100%] lg:px-[7%]  text-white tracking-[4px] lg:tracking-normal'>
  <Link to="/about" className='hover:underline hover:underline-offset-[15px] hover:decoration-[10px]'>A b o u t</Link>

  <div className='relative group'>
    <Link to="/artists" className=''>
      A r t i s t s
    </Link>
    <div className='absolute border-t-[10px] border-white left-0 top-full hidden group-hover:flex flex-col w-[100%] pt-[10%] tracking-normal'>
  {dataInfo.slice(0, 5).map((artist) => (
    <div key={artist.id} className='w-full'>
      <p className='text-[1vw] px-[5%] hover:text-[#545454] cursor-pointer'>{artist.artistName}</p>
    </div>
  ))}
  {dataInfo.length > 5 && (
    <div className='w-full'>
      <p className='text-[0.8vw] px-[5%] hover:text-[#545454] cursor-pointer '>...</p>
    </div>
  )}
</div>

    
  </div>
  
  <Link className='hover:underline hover:underline-offset-[15px] hover:decoration-[10px]'>P r e s s</Link>
  <Link className='hover:underline hover:underline-offset-[15px] hover:decoration-[10px]'>A u c t i o n s</Link>
  <Link className='hover:underline hover:underline-offset-[15px] hover:decoration-[10px]'>C o n t a c t</Link>
</div>

  
    <div className=' h-[80px] w-[90%] hidden lg:flex p-[2%] justify-between items-center '>
      <img src={logo} className=' w-[120px]  z-[500]' alt='the-market-place-logo'/>
    {menuOpen ? (
            <AiOutlineClose onClick={toggleMenu}  className=" w-[20px] h-[20px] z-[500] text-white " />
          ) : (
            <FaBarsStaggered onClick={toggleMenu}  className=" w-[20px] h-[20px] z-[500] text-white " />
          )}
<div
        className={`fixed inset-0 transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}
      >
      <div className=" bg-[#C3E0E6] hidden lg:flex pt-[5%]  justify-center items-center flex-col  h-full text-[20px] gap-[5%] text-white">
      <Link to="/about">A b o u t</Link>
      <div className='flex justify-center items-center flex-col text-center  w-[100%]'>

        <Link to='/artists' className=''>A r t i s t s</Link>
        <div className=' border-y-[1px] border-white py-[5%]  flex flex-col w-[90%]'>
  {dataInfo.slice(0, 6).map((artist) => (
    <div key={artist.id} className='w-full '>
      <p className='hover:text-[#545454] cursor-pointer md:text-[3vw] w-[100%] py-[0.5%]'>{artist.artistName}</p>
    </div>
  ))}
  {dataInfo.length > 6 && (
 
      <p className='hover:text-[#545454] cursor-pointer w-[100%] '>...</p>
 
  )}
  </div>
</div>
        <Link>P r e s s</Link>
        <Link>A u c t i o n s</Link>
        <Link>C o n t a c t</Link>
        
      </div>
      </div>

    </div>
    </div>
  )
}

export default Navbar