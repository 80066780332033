import React from 'react'
import dataInfo from "../ArtistsData/data"
import about from "../../images/AboutUs/box3.png"
import logo from "../../images/Artists/logo.png"
import box1 from "../../images/Artists/box.png"
import box2 from "../../images/Artists/box2.png"
import { Link } from 'react-router-dom'
function AboutUs() {
  return (
    <div className='md:h-[auto] flex  items-end w-[88%] xl:w-[92%] md:px-[2%] flex-col py-[2%] pb-[5%] '>
      
      <div className='  flex justify-between xl:justify-end  gap-[5%] xl:gap-[4%] lg:gap-[2%] md:flex-col-reverse  w-[85.8%] md:w-[95%] xl:w-[100%]'>
        <div className='  flex flex-col gap-[50px] lg:gap-[25px] w-[27vw]   lg:w-[29vw] md:w-[100%] md:flex-row md:my-[5%] '>
       
          <div>
             <img className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw] ' src={about} alt='about'/>
         </div>
          <Link to={`/artist-biography/${dataInfo[0].artistLink}`}>
             <img className='w-[22.1vw] xl:w-[25.5vw] md:w-[40vw] ' src={ dataInfo[0].mainImage}/>
             <p className='py-[1%] text-[20px] lg:text-[12px] xl:text-[18px]'>{dataInfo[0].artistName}</p>
          </Link>
          
        </div>   
        <div className=' h-[fit-content] flex flex-col gap-[10px] w-[60vw] xl:w-[55vw] lg:w-[55vw] md:w-[100%]'>
          <h1 className='text-[30px] font-[700] text-[#0A76BE] lg:text-[25px]'>dear art lovers</h1>
          <p className=' text-[20px] xl:text-[18px] lg:text-[15px]'>Sed utque laudantium, totamam est, velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
          <p className=' text-[20px] xl:text-[18px] lg:text-[15px]'>Sed ut perspiciatis unde omn, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commod"</p>
        </div>
     </div>
    
    </div>
  )
}

export default AboutUs