
import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './Pages/HomePage';
import ArtistsPage from './Pages/ArtistsPage';
import AboutUsPage from './Pages/AboutUsPage';
import AsrtistBioPage from './Pages/AsrtistBioPage';


function App() {
  return (
    <>
    <Routes>
      <Route exact path='/' Component={HomePage} />
      <Route path='/artists' Component={ArtistsPage} />
      <Route path='/about' Component={AboutUsPage} />
      <Route path='/artist-biography/:artistLink' Component={AsrtistBioPage} />
    </Routes>
     
    </>
  );
}

export default App;
